<template>
  <div
    class="banner"
    ref="bannerBox"
    :style="{ height: this.isMobile ? 'auto' : `${visibleWH.height}px` }"
  >
    <ShrinkImg>
      <img
        :src="imgUrl"
        alt="banner"
        class="img"
        ref="bannerImg"
        v-show="show"
      />
    </ShrinkImg>
  </div>
</template>

<script>
import { getContainerWH, calculateToCenter } from '@/utils/common';
import { mapState } from 'vuex';
import _ from 'lodash';
import { ShrinkImg } from '@/components/Transition';
export default {
  components: {
    ShrinkImg,
  },
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    imgWH: {
      type: Array,
      default: () => [1920, 1080],
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
    this.$nextTick(() => {
      this.init();
      window.addEventListener('resize', _.debounce(this.init, 50));
    });
  },
  methods: {
    init() {
      // !created中无法获取到this.$refs.xxx
      if (!this.isMobile) {
        const { width } = getContainerWH(this.$refs.bannerBox);
        calculateToCenter(this.$refs.bannerImg, ...this.imgWH, width, this.visibleWH.height);
      } else {
        this.$refs.bannerImg.removeAttribute('style');
      }
    },
  },
  computed: {
    ...mapState(['isMobile', 'visibleWH']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.banner {
  height: 100vh;
  overflow: hidden;
  position: relative;
  .img {
    position: relative;
    display: block;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  @media screen and (max-width: $res-point-6) {
    height: auto;
    .img {
      width: 100%;
      position: static;
    }
  }
}
</style>