<template>
  <div
    class="about-intro"
    id="aboutProfile"
  >
    <StoryTitle>{{ baseInfo.aboutProduct }}</StoryTitle>
    <div
      class="about-intro-cont"
      v-if="show"
    >
      <div class="txts">
        <AboutTitle :data="{
        ch: baseInfo.aboutLeftChinese,
        en: baseInfo.aboutLeftEnglish,
      }" />
        <p
          class="desc"
          v-html="baseInfo.aboutLeftContent"
        ></p>
        <div class="about-intro-statistics">
          <AboutStatistics
            v-for="item in statisticsData"
            :key="item.id"
            :data="item"
            class="item"
          />
        </div>

      </div>
      <ScaleImage class="pic">
        <PlaceholderImage
          :src="baseInfo.aboutRightImage"
          :width="717"
          :height="500"
        />
      </ScaleImage>
    </div>
    <el-empty v-else />
  </div>
</template>

<script>
import { StoryTitle, AboutTitle } from '../../components/Title';
import { statisticsData } from '../data';
import { AboutStatistics } from '../../components/Statistics';
import { Scale, Placeholder } from '@/components/Image';
import { mapState } from 'vuex';
export default {
  components: {
    StoryTitle,
    AboutTitle,
    AboutStatistics,
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.about.baseInfo,
    }),
    show() {
      return JSON.stringify(this.baseInfo) !== '{}';
    },
    statisticsData() {
      return statisticsData.map((d) => ({ ...d, value: this.baseInfo[d.key] }));
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.about-intro {
  padding: math.percentage(math.div(146, 1920)) math.percentage(math.div(190, 1920))
    math.percentage(math.div(142, 1920)) 235px;
  &-cont {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-left: 60px;
    border-top: 1px solid rgb(72, 92, 126, 0.2);
    border-bottom: 1px solid rgb(72, 92, 126, 0.2);
    .pic {
      width: math.percentage(math.div(717, 1434));
    }
    .txts {
      width: math.percentage(math.div(560, 1434));
      padding-top: math.percentage(math.div(80, 1434));
    }
    .desc {
      line-height: 2;
      margin-top: 30px;
    }
  }
  &-statistics {
    margin-top: 35px;
    display: flex;
    .item {
      + .item {
        margin-left: 40px;
      }
    }
  }
  @media screen and (max-width: $res-point-1) {
    &-cont {
      .txts {
        width: math.percentage(math.div(660, 1434));
        padding-top: math.percentage(math.div(80 * 0.8 * 0.8, 1434));
      }
      .desc {
        line-height: 2 * 0.8;
      }
    }
    &-statistics {
      .item {
        + .item {
          margin-left: 40px * 0.8;
        }
      }
    }
    ::v-deep {
      .about-title {
        font-size: 24px * 0.8;
        .en {
          margin-bottom: 10px * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-2) {
    &-statistics {
      margin-top: 35px * 0.8;
    }
    &-cont {
      .desc {
        margin-top: 30px * 0.8;
      }
    }
    ::v-deep {
      .about-title {
        font-size: 18px;
        .en {
          margin-bottom: 5px;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-3) {
    padding-left: 235px - 60px;
    &-cont {
      padding-top: math.percentage(math.div(80, 1434));
      border-bottom: none;
      .txts {
        padding-top: 0;
      }
      .desc {
        margin-top: 30px;
      }
    }
    &-statistics {
      margin-top: 35px;
    }
    ::v-deep {
      .about-title {
        font-size: 24px * 0.8;
        .en {
          margin-bottom: 10px;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-5) {
    padding-left: 5%;
    padding-right: 5%;
    &-cont {
      margin-left: 0;
    }
  }
  @media screen and (max-width: $res-point-8) {
    ::v-deep {
      .about-title {
        font-size: 24px * 0.8 * 0.8;
      }
    }
    &-cont {
      margin-top: math.percentage(math.div(40, 640));
      flex-wrap: wrap;
      .pic {
        width: 100%;
        margin-top: math.percentage(math.div(40, 640));
      }
      .txts {
        width: 100%;
      }
    }
  }
}
</style>