<template>
  <div
    class="about-history"
    id="aboutDevelopment"
  >
    <AboutTitle
      class="about-history-title"
      :data="{
      ch: baseInfo.aboutDevelopChiese,
      en: baseInfo.aboutDevelopEnglish,
    }"
    />
    <ScaleImage class="pic">
      <PlaceholderImage
        :width="1435"
        :height="500"
        :src="baseInfo.aboutDevelopImage"
        :alt="baseInfo.aboutDevelopChiese"
      />
    </ScaleImage>
    <div v-loading="loading">
      <el-tabs
        v-model="activeName"
        class="about-history-tabs"
        v-if="historyData.length"
      >
        <el-tab-pane
          :label="item.label"
          :name="item.name"
          v-for="item in tabsList"
          :key="item.key"
        >
          <div class="about-history-list">
            <div
              class="about-history-item"
              v-for="historyItem in item.children"
              :key="historyItem.id"
            >
              <p class="year">{{ historyItem.year }}</p>
              <p
                class="desc"
                v-html="historyItem.desc"
              ></p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-empty v-else />
    </div>
  </div>
</template>

<script>
import { AboutTitle } from '../../components/Title';
import { Scale, Placeholder } from '@/components/Image';
import { getDevelop } from '@/api/ShuJiuXiang/about';
import { mapState } from 'vuex';
export default {
  components: {
    AboutTitle,
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
  },
  data() {
    return {
      historyData: [],
      activeName: '0',
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const res = await getDevelop();
        this.loading = false;
        this.historyData = res.map((d) => ({
          id: d.honorId,
          year: d.honorTitle,
          desc: d.honorContent,
        }));
      } catch (error) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.about.baseInfo,
    }),
    tabsList() {
      let index = 0;
      const tabs = [];
      const step = 4;
      const len = this.historyData.length - 1;
      while (index <= len) {
        const arr = this.historyData.slice(index, index + step);
        const label = arr.length > 1 ? `${arr[0].year} - ${arr[arr.length - 1].year}` : arr[0].year;
        tabs.push({ label, key: `${index}`, children: arr });
        index = index + step;
      }
      return tabs;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.about-history {
  border-top: 1px solid rgba(14, 58, 135, 0.2);
  border-bottom: 1px solid rgba(14, 58, 135, 0.2);
  margin: 0 math.percentage(math.div(190, 1920)) 0 295px;
  padding-top: math.percentage(math.div(100, 1920));
  padding-bottom: 60px;
  &-list {
    display: flex;
  }
  &-item {
    width: math.percentage(math.div(320, 1434));
    + .about-history-item {
      margin-left: 50px;
    }
    .year {
      font-size: 32px;
      line-height: math.div(43, 32);
      color: $sub-color-2;
      margin-bottom: 24px;
    }
    .desc {
      line-height: 2;
    }
  }
  &-tabs {
    ::v-deep {
      .el-tabs__header {
        margin-bottom: 60px;
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background: rgba(14, 58, 135, 0.2);
      }
      .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
      }
      .el-tabs__active-bar {
        height: 1px;
        background: $sub-color-2;
      }
      .el-tabs__item {
        font-size: 16px;
        height: auto;
        line-height: math.div(21, 16);
        padding: 21px 25px;
        &.is-active {
          font-weight: bold;
          color: $sub-color-2;
        }
        &:hover {
          color: $sub-color-2;
        }
      }
      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        top: 50%;
        margin-top: -22px;
      }
    }
  }
  &-title {
    margin-bottom: 38px;
  }
  @media screen and (max-width: $res-point-3) {
    margin-left: 295px - 60px;
  }
  @media screen and (max-width: $res-point-5) {
    margin-left: 5%;
    margin-right: 5%;
  }
  @media screen and (max-width: $res-point-6) {
    &-item {
      + .about-history-item {
        margin-left: 30px;
      }
    }
  }
  @media screen and (max-width: $res-point-7) {
    padding-bottom: 0;
    &-list {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-item {
      width: 48%;
      margin-bottom: 30px;
      + .about-history-item {
        margin-left: 0;
      }
      .year {
        font-size: 32px * 0.8;
        margin-bottom: 10px;
      }
    }
    &-tabs {
      ::v-deep {
        .el-tabs__header {
          margin-bottom: 30px;
        }
      }
    }
  }

  @media screen and (max-width: $res-point-10) {
    &-title {
      margin-bottom: math.div(38, 600) * 100vw;
    }
    &-tabs {
      ::v-deep {
        .el-tabs__header {
          margin-bottom: math.div(30, 600) * 100vw;
        }
        .el-tabs__item {
          font-size: 14px;
          padding: 13px math.div(25, 600) * 100vw;
        }
        .el-tabs__nav-next,
        .el-tabs__nav-prev {
          top: 50%;
          margin-top: -22px;
        }
      }
    }
    &-item {
      margin-bottom: math.div(30, 600) * 100vw;
      .year {
        font-size: 18px;
        margin-bottom: 10px * 0.8;
      }
      .desc {
        font-size: 12px;
        line-height: 1.8;
      }
    }
  }
}
</style>