import request from '@/utils/request';

const PATH = '/v1/about';

// 1.	基本信息
export function getBaseInfo(params = {}) {
  return request({
    url: PATH + '/info',
    method: 'GET',
    params,
  });
}

// 2.	食品安全
export function getSafety(params = {}) {
  return request({
    url: PATH + '/safety',
    method: 'GET',
    params,
  });
}

// 3.	公司发展
export function getDevelop(params = {}) {
  return request({
    url: PATH + '/develop',
    method: 'GET',
    params,
  });
}

// 4.	公司荣誉
export function getHonor(params = {}) {
  return request({
    url: PATH + '/honor',
    method: 'GET',
    params,
  });
}

// 5.	旗下品牌
export function getBrand(params = {}) {
  return request({
    url: PATH + '/brand',
    method: 'GET',
    params,
  });
}
