<template>
  <div
    class="about-honor"
    id="aboutHonor"
  >
    <ScaleImage class="pic">
      <PlaceholderImage
        :src="baseInfo.aboutHonorImage"
        :width="717"
        :height="640"
        :alt="baseInfo.aboutHonorChinese"
      />
    </ScaleImage>
    <div class="txts">
      <AboutTitle
        class="about-honor-title"
        :data="{
      ch: baseInfo.aboutHonorChinese,
      en: baseInfo.aboutHonorEnglish,
    }"
      />
      <div v-loading="loading">
        <div
          class="about-honor-list"
          v-if="honorData.length"
        >
          <div
            class="item"
            v-for="item in honorData"
            :key="item.id"
          >
            <PlaceholderImage
              class="img"
              :src="item.developImage"
              :width="305"
              :height="229"
            />
            <p class="title">{{ item.developTitle }}</p>
          </div>
        </div>
        <el-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { Placeholder, Scale } from '@/components/Image';
import { AboutTitle } from '../../components/Title';
import { mapState } from 'vuex';
import { getHonor } from '@/api/ShuJiuXiang/about';
export default {
  components: {
    PlaceholderImage: Placeholder,
    ScaleImage: Scale,
    AboutTitle,
  },
  data() {
    return {
      honorData: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const res = await getHonor();
        this.honorData = res.map((d) => ({ ...d, id: d.developId }));
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.about.baseInfo,
    }),
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.about-honor {
  margin: math.percentage(math.div(130, 1920)) math.percentage(math.div(190, 1920))
    math.percentage(math.div(130, 1920)) 295px;
  border-top: 1px solid rgba(14, 58, 135, 0.2);
  border-bottom: 1px solid rgba(14, 58, 135, 0.2);
  display: flex;
  .pic {
    width: math.percentage(math.div(717, 1434));
    margin-right: math.percentage(math.div(76, 1434));
  }
  .txts {
    flex: 1;
    padding-top: math.percentage(math.div(120, 1434));
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 50%;
      position: relative;
      margin-bottom: 15px;
      .img {
        width: 305px;
        position: absolute;
        top: -75px;
        left: 40px;
        @include hide;
        transition: 300ms;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.8);
        }
      }
      .title {
        position: relative;
        line-height: 2;
        transition: 300ms;
        z-index: 2;
      }
      @media screen and (min-width: $hover-point) {
        &:hover {
          .title {
            color: $sub-color-2;
            font-weight: bold;
          }
          .img {
            @include show;
          }
        }
      }
    }
  }
  &-title {
    position: relative;
    z-index: 2;
    margin-bottom: 55px;
  }
  @media screen and (max-width: $res-point-1) {
    .txts {
      padding-top: math.percentage(math.div(120 * 0.8, 1434));
    }
  }
  @media screen and (max-width: $res-point-2) {
    .txts {
      padding-top: math.percentage(math.div(120 * 0.8 * 0.8, 1434));
    }
    &-title {
      margin-bottom: 55px * 0.8;
    }
    &-list {
      .item {
        margin-bottom: 15px * 0.8;
        .img {
          width: 305px * 0.8;
          top: -75px * 0.8;
        }
      }
    }
    ::v-deep {
      .about-title {
        font-size: 24px * 0.8;
        .en {
          margin-bottom: 10px * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-3) {
    margin-left: 295px - 60px;
    &-title {
      margin-bottom: 55px * 0.8 * 0.8;
    }
    &-list {
      .item {
        margin-bottom: 15px * 0.8 * 0.8;
        .img {
          width: 305px * 0.8 * 0.8;
          top: -75px * 0.8 * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-5) {
    margin-left: 5%;
    margin-right: 5%;
  }
  @media screen and (max-width: $res-point-6) {
    &-list {
      .item {
        .img {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-7) {
    flex-wrap: wrap;
    border-top: none;
    border-bottom: none;
    .pic {
      width: 100%;
      margin-right: 0;
      order: 2;
      margin-top: math.percentage(math.div(60, 860));
    }
    .txts {
      flex: auto;
      order: 1;
    }
  }
  @media screen and (max-width: $res-point-8) {
    ::v-deep {
      .about-title {
        font-size: 24px * 0.8 * 0.8;
        .en {
          margin-bottom: 10px;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-10) {
    &-title {
      margin-bottom: math.div(44, 600) * 100vw;
    }
  }
}
</style>
