<template>
  <div class="bannerText">
    <BannerTitle
      :ch="ch"
      :en="en"
    >
      <BannerNav
        v-bind="$attrs"
        v-on="$listeners"
        v-if="isShowNav && !isMobile"
      />
    </BannerTitle>
  </div>
</template>

<script>
import Title from './Title.vue';
import Nav from './Nav.vue';
import { mapState } from 'vuex';
export default {
  inheritAttrs: false,
  components: {
    BannerTitle: Title,
    BannerNav: Nav,
  },
  props: {
    ch: {
      type: String,
      default: '',
    },
    en: {
      type: String,
      default: '',
    },
    isShowNav: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['isMobile']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.bannerText {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 (280 / 1920) * 100% 0 (235 / 1920) * 100%;
  margin-right: 180px;
  color: #fff;
  @media screen and (max-width: $res-point-6) {
    margin-right: 0;
    padding-right: (235 / 1920) * 100%;
  }
  @media screen and (max-width: $res-point-8) {
    padding: 0 5%;
  }
}
</style>