<template>
  <div class="page-about">
    <PageBannerBox>
      <FSImg :imgUrl="baseInfo.aboutTopImage" />
      <BannerText
        :en="baseInfo.aboutTopEnglish"
        :ch="baseInfo.aboutTopChinese"
        :navList="navList"
        @changeActiveNav="changeActiveNav"
        :active="currentNav"
      />
      <SJXPoint v-if="!isMobile" />
    </PageBannerBox>
    <PageContent>
      <AboutIntro />
      <AboutSafety />
      <AboutHistory />
      <AboutHonor />
      <AboutBrand />
    </PageContent>
    <SideNav
      :navList="navList"
      :active="currentNav"
      @changeActiveNav="changeActiveNav"
      v-if="!isNearMobile"
    />
  </div>
</template>

<script>
import { FSImg } from '@/components/Banner';
import BannerText from '../components/BannerText';
import PageBannerBox from '../components/Layout/PageBannerBox.vue';
import { SJXPoint } from '@/components/DownPoint';
import PageContent from '../components/Layout/PageContent.vue';
import { navList } from './data';
import { mapState } from 'vuex';
import AboutIntro from './components/Intro.vue';
import AboutSafety from './components/Safety.vue';
import AboutHistory from './components/History.vue';
import AboutHonor from './components/Honor.vue';
import AboutBrand from './components/Brand.vue';
import { getBaseInfo } from '@/api/ShuJiuXiang/about';
import { getPointsPos, getScrollTop } from '@/utils/common';
import $ from 'jquery';
import _ from 'lodash';
import SideNav from '../components/SideNav';

export default {
  components: {
    FSImg,
    BannerText,
    PageBannerBox,
    SJXPoint,
    PageContent,
    AboutIntro,
    AboutSafety,
    AboutHistory,
    AboutHonor,
    AboutBrand,
    SideNav,
  },
  data() {
    return {
      navList,
      currentNav: '',
    };
  },
  mounted() {
    this.getData();
    this.init();
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    async getData() {
      const res = await getBaseInfo();
      this.$store.commit('sjx/about/BASE_INFO', res);
    },
    changeActiveNav(key) {
      const index = navList.findIndex((d) => d.key === key);
      $('html,body').stop().animate({ scrollTop: this.pointsPos[index] }, 800);
      this.currentNav = key;
    },
    async init() {
      const res = await getPointsPos(this.navList);
      this.pointsPos = res;
      if (this.isNearMobile) {
        return;
      }
      this.onScroll();
      document.addEventListener('scroll', _.debounce(this.onScroll, 50));
    },
    onScroll() {
      const st = getScrollTop() + this.visibleWH.height * 0.5;
      let len = this.pointsPos.length - 1;
      let index = 0;
      while (index <= len) {
        const next = this.pointsPos[index + 1] || Infinity;
        if (st >= this.pointsPos[index] && st < next) {
          this.currentNav = this.navList[index].key;
          break;
        }
        index++;
      }
    },
  },
  computed: {
    ...mapState({
      isMobile: 'isMobile',
      isNearMobile: 'isNearMobile',
      visibleWH: 'visibleWH',
      baseInfo: (state) => state.sjx.about.baseInfo,
    }),
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.page-about {
  overflow: hidden;
  ::v-deep .pageContent {
    padding: 0 0 math.percentage(math.div(230, 1920)) 0;
  }
  @media screen and (max-width: $res-point-6) {
    padding-top: 60px;
  }
}
</style>
