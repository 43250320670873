<template>
  <div class="about-statistics">
    <p class="text">{{ data.text }}</p>
    <p class="value">{{ data.value }}</p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.about-statistics {
  color: $sub-color-2;
  .text {
    font-size: 16px;
    text-transform: uppercase;
    line-height: math.div(17, 16);
    margin-bottom: 8px;
  }
  .value {
    font-size: 48px;
    line-height: math.div(53, 48);
  }
  @media screen and (max-width: $res-point-1) {
    .text {
      font-size: 14px;
      margin-bottom: 8px * 0.8;
    }
    .value {
      font-size: 48px * 0.8;
    }
  }
  @media screen and (max-width: $res-point-2) {
    .text {
      font-size: 12px;
      margin-bottom: 8px * 0.8 * 0.8;
    }
    .value {
      font-size: 48px * 0.8 * 0.8;
    }
  }
}
</style>