<template>
  <div class="titleBox">
    <RToLMove>
      <p
        class="en"
        v-if="show"
      >{{ en }}</p>
    </RToLMove>
    <div class="ch-box">
      <RToLMove>
        <p
          class="ch"
          v-if="show"
        >{{ ch }}</p>
      </RToLMove>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { RToLMove } from '@/components/Transition';

export default {
  components: {
    RToLMove,
  },
  props: {
    en: {
      type: String,
      default: 'News',
    },
    ch: {
      type: String,
      default: '新闻资讯',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.titleBox {
  width: 100%;
  .ch-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .en {
    font-size: 24px;
    text-transform: uppercase;
    line-height: 1.16;
  }
  .ch {
    font-size: 48px;
    line-height: 1.33;
    margin-top: 24px;
    &::v-deep.RToLMove-enter-active {
      transition-delay: 200ms;
    }
  }
  @media screen and (max-width: $res-point-8) {
    .en {
      font-size: 20px;
    }
    .ch {
      font-size: 38px;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: $res-point-9) {
    .en {
      font-size: 16px;
    }
    .ch {
      font-size: 30px;
      margin-top: 15px;
    }
  }
}
</style>