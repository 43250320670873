<template>
  <ul class="nav">
    <li
      v-for="item in navList"
      :key="item.key"
      class="item"
      :class="active === item.key ? 'active' : ''"
      @click="switchNav(item.key, item.url)"
    >{{ item.text }}</li>
  </ul>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: () => [
        { text: '近期活动', key: 'activity', url: '/sjx/news/activity' },
        { text: '新闻公告', key: 'notice', url: '/sjx/news/notice' },
        { text: '视频中心', key: 'video', url: '/sjx/news/video' },
      ],
    },
    active: {
      type: String,
      default: 'activity',
    },
  },
  methods: {
    switchNav(key, url) {
      this.$emit('changeActiveNav', key, url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.nav {
  display: flex;
  align-items: center;
  .item {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    + .item {
      margin-left: 30px;
    }
    &.active {
      color: $active-color-1;
    }
    @media screen and (min-width: $hover-point) {
      transition: 300ms;
      &:not(.active):hover {
        color: $active-color-1;
      }
    }
  }
}
</style>