<template>
  <div
    class="about-brand"
    id="aboutBrands"
  >
    <AboutTitle
      class="about-brand-title"
      :data="{
      ch: baseInfo.aboutBrandChinese,
      en: baseInfo.aboutBrandEnglish,
    }"
    />
    <div v-loading="loading">
      <div
        class="about-brand-list"
        v-if="brandData.length"
      >
        <div
          v-for="item in brandData"
          :key="item.id"
          class="item"
        >
          <ScaleImage class="pic">
            <PlaceholderImage
              :src="item.brandImage"
              :width="717"
              :height="500"
              :alt="item.brandTitle"
            />
          </ScaleImage>
          <div class="txts">
            <p class="title">{{ item.brandTitle }}</p>
            <p
              class="desc"
              v-html="item.brandContent"
            ></p>
          </div>
        </div>
      </div>
      <el-empty v-else />
    </div>
  </div>
</template>

<script>
import { AboutTitle } from '../../components/Title';
import { Scale, Placeholder } from '@/components/Image';
import { mapState } from 'vuex';
import { getBrand } from '@/api/ShuJiuXiang/about';
export default {
  components: {
    AboutTitle,
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
  },
  data() {
    return {
      brandData: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const res = await getBrand();
        this.brandData = res.map((d) => ({ ...d, id: d.brandId }));
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.about.baseInfo,
    }),
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.about-brand {
  position: relative;
  border-top: 1px solid rgba(14, 58, 135, 0.2);
  border-bottom: 1px solid rgba(14, 58, 135, 0.2);
  margin: 0 math.percentage(math.div(190, 1920)) 0 295px;
  &-list {
    .item {
      display: flex;
      align-items: flex-end;
      &:nth-child(2n-1) {
        .pic {
          order: 2;
          margin-right: 0;
          margin-left: math.percentage(math.div(100, 1434));
        }
      }
    }
    .pic {
      width: math.percentage(math.div(717, 1434));
      margin-right: math.percentage(math.div(100, 1434));
    }
    .txts {
      flex: 1;
      overflow: hidden;
      padding-bottom: math.percentage(math.div(60, 1434));
    }
    .title {
      font-size: 20px;
      color: $sub-color-2;
      line-height: math.div(27, 20);
      margin-bottom: 25px;
    }
    .desc {
      line-height: 2;
    }
  }
  &-title {
    position: absolute;
    z-index: 29;
    left: 0;
    top: 0;
    margin-top: math.percentage(math.div(100, 1434));
  }
  @media screen and (max-width: $res-point-1) {
    &-title {
      margin-top: math.percentage(math.div(100 * 0.8 * 0.8, 1434));
    }
    ::v-deep {
      .about-title {
        font-size: 24px * 0.8;
        .en {
          margin-bottom: 10px * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-2) {
    &-list {
      .title {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
  }
  @media screen and (max-width: $res-point-3) {
    margin-left: 295px - 60px;
  }
  @media screen and (max-width: $res-point-5) {
    margin-left: 5%;
    margin-right: 5%;
  }
  @media screen and (max-width: $res-point-6) {
    border-bottom: none;
    &-title {
      position: static;
      margin-bottom: math.percentage(math.div(80, 1434));
    }
  }
  @media screen and (max-width: $res-point-7) {
    &-list {
      .item {
        &:nth-child(2n-1) {
          .pic {
            margin-left: math.percentage(math.div(60, 1434));
          }
        }
      }
      .pic {
        margin-right: math.percentage(math.div(60, 1434));
      }
      .txts {
        padding-bottom: math.percentage(math.div(40, 1434));
      }
      .title {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .desc {
        line-height: 1.8;
        font-size: 13px;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    &-list {
      .item {
        background: #fff;
        border-radius: 6px;
        overflow: hidden;
        flex-wrap: wrap;
        box-shadow: $card-shadow-1;
        + .item {
          margin-top: 15px;
        }
        &:nth-child(2n-1) {
          .pic {
            order: 0;
            margin-left: 0;
          }
        }
      }
      .pic {
        width: 100%;
        margin-right: 0;
      }
      .txts {
        flex: auto;
        padding-bottom: 0;
        padding: 20px 15px;
      }
      .title {
        font-weight: bold;
      }
      .desc {
        font-size: 14px;
      }
    }
  }
}
</style>