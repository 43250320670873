<template>
  <div class="sideNav">
    <transition name="sideNav">
      <div
        class="box"
        v-if="show"
      >
        <span
          class="line"
          :style="{ top: moveLineTop + 'px' }"
        ></span>
        <div
          v-for="item in navList"
          :key="item.key"
          class="item"
          @click="changeNav(item, $event)"
          :class="{ active: item.key === active }"
        >{{ item.text }}</div>
        <img
          :src="require('@/assets/ShuJiuXiang/b-down-arrow@2x.png')"
          alt=""
          class="arrow"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { getScrollTop } from '@/utils/common';
import { mapState } from 'vuex';
export default {
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: '',
    },
    always: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      moveLineTop: 0,
    };
  },
  mounted() {
    this.onScroll();
    document.addEventListener('scroll', _.debounce(this.onScroll, 50));
  },
  methods: {
    onScroll() {
      const st = getScrollTop();
      this.show = this.always || st > this.visibleWH.height / 2;
    },
    changeNav(itemData) {
      this.$emit('changeActiveNav', itemData.key, itemData.url);
    },
    setLineTop() {
      this.$nextTick(() => {
        const el = document.querySelector('.sideNav .active');
        this.moveLineTop = el?.offsetTop || 0;
      });
    },
  },
  computed: {
    ...mapState(['isMobile', 'visibleWH']),
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  },
  watch: {
    $route() {
      this.setLineTop();
    },
    show() {
      this.setLineTop();
    },
    active() {
      this.setLineTop();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.sideNav {
  position: fixed;
  z-index: 999;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  .box {
    border-left: 1px solid $border-color-3;
    padding-left: 30px;
    position: relative;
  }
  .item {
    font-size: 16px;
    line-height: 18px;
    height: 18px;
    cursor: pointer;
    + .item {
      margin-top: 46px;
    }
    &.active {
      color: $sub-color-2;
    }
    @media screen and (min-width: $hover-point) {
      &:hover {
        color: $sub-color-2;
      }
    }
  }
  .line {
    position: absolute;
    top: 0;
    height: 18px;
    width: 1px;
    background: $sub-color-2;
    left: -1px;
    transition: 400ms linear;
  }
  .arrow {
    position: absolute;
    width: 26px;
    height: 18px;
    top: 100%;
    left: -13px;
    margin-top: 30px;
  }
}
.sideNav-enter {
  transform: translateY(-80%);
  opacity: 0;
}
.sideNav-leave-to {
  transform: translateY(80%);
  opacity: 0;
}
.sideNav-leave-active,
.sideNav-enter-active {
  transition: 1000ms;
}
</style>