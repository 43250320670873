export const statisticsData = [
  {
    id: 1,
    text: 'since',
    key: 'aboutLeftSince',
  },
  {
    id: 2,
    text: '全国门店',
    key: 'aboutLeftNumber',
  },
];

export const navList = [
  { text: '公司简介', key: 'aboutProfile' },
  { text: '公司发展', key: 'aboutDevelopment' },
  { text: '企业荣誉', key: 'aboutHonor' },
  { text: '旗下品牌', key: 'aboutBrands' },
];
