<template>
  <div class="about-safety">
    <FixBg
      v-loading="loading"
      class="bg"
      :src="require('@/assets/ShuJiuXiang/story-bg.jpg')"
    >
      <div class="about-safety-top">
        <div class="titleBox">
          <p class="en">{{ baseInfo.aboutFoodSafety }}</p>
        </div>
        <div
          class="about-safety-nav"
          v-if="!isMobile"
        >
          <div
            v-for="(item, index) in safetyData"
            :key="item.id"
            class="item"
            :class="{ active: activeIndex === index}"
            @click="changeActiveIndex(index)"
            :data-index="index"
          >{{ item.safetyTitle }}</div>
        </div>
      </div>
    </FixBg>
    <!-- bg -->
    <div
      class="about-safety-swiper"
      v-if="safetyData.length"
    >
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="item in safetyData"
          :key="item.id"
        >
          <div class="about-safety-card">
            <ScaleImage class="pic">
              <PlaceholderImage
                :src="item.safetyImage"
                :alt="item.safetyTitle"
                :width="1090"
                :height="700"
              />
            </ScaleImage>
            <div class="txts">
              <p class="title">{{ item.safetyTitle }}</p>
              <p
                class="desc"
                v-html="item.safetyContent"
              ></p>
            </div>
          </div>
        </swiper-slide>
        <div
          slot="pagination"
          class="about-safety-pagination"
        ></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { FixBg } from '@/components/Image';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import { Scale, Placeholder } from '@/components/Image';
import { mapState } from 'vuex';
import { getSafety } from '@/api/ShuJiuXiang/about';
export default {
  components: {
    FixBg,
    swiperSlide,
    swiper,
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
  },
  data() {
    const $this = this;
    return {
      safetyData: [],
      swiperOptions: {
        loop: true,
        speed: 1000, // 切换速度
        autoplay: {
          delay: 8000,
          pauseOnMouseEnter: true, // 鼠标置于swiper时暂停自动切换，鼠标离开时恢复自动切换
        },
        grabCursor: true, // 拖动时指针会变成抓手形状
        pagination: {
          el: '.about-safety-pagination',
          clickable: true,
          bulletClass: 'p-item',
          bulletActiveClass: 'p-active-item',
        },
        on: {
          slideChange() {
            let index = this.activeIndex;
            const len = $this.safetyData.length;
            index = index > len ? index - len : index;
            $this.activeIndex = index - 1;
          },
        },
      },
      activeIndex: 0,
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    changeActiveIndex(index) {
      this.swiper?.slideTo(index + 1, 1000, false);
      this.$nextTick(() => {
        this.activeIndex = index;
      });
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getSafety();
        this.loading = false;
        this.safetyData = res.map((d) => ({ ...d, id: d.safetyId }));
      } catch (error) {
        this.loading = false;
      }
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper?.swiper;
    },
    ...mapState({
      isMobile: 'isMobile',
      baseInfo: (state) => state.sjx.about.baseInfo,
    }),
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.titleBox {
  height: 60px;
  display: flex;
  align-items: flex-end;
  border-left: 2px solid #fff;
  padding-left: 24px;
  font-size: 24px;
  line-height: 1;
  @media screen and (max-width: $res-point-6) {
    font-size: 24px * 0.8;
    border-width: 1px;
    padding-left: 16px;
    color: $sub-color-2;
    border-color: $sub-color-2;
    height: auto;
  }
}
.about-safety {
  padding-bottom: math.percentage(math.div(150, 1920));
  &-top {
    color: #fff;
    display: flex;
    align-items: flex-end;
    padding-bottom: 60px;
  }
  .bg {
    padding: 40px 0 math.percentage(math.div(555, 1920)) 295px;
  }
  .titleBox {
    margin-right: math.percentage(math.div(165, 1920 - 235 - 60));
  }
  &-nav {
    display: flex;
    align-items: center;
    .item {
      cursor: pointer;
      line-height: 24px;
      position: relative;
      font-size: 16px;
      padding-left: 14px;
      transition: 300ms;
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        background: #fff;
        height: 12px;
        left: 0;
        top: 50%;
        margin-top: -6px;
        transition: 300ms;
      }
      + .item {
        margin-left: 75px;
      }
      &.active {
        color: $sub-color-6;
        &::before {
          background: $sub-color-6;
        }
      }
    }
  }
  &-swiper {
    margin-left: 295px;
    margin-top: math.percentage(math.div(-555, 1920));
  }
  &-card {
    display: flex;
    color: #fff;
    justify-content: space-between;
    .txts {
      order: 1;
      width: 320px;
      padding-top: math.percentage(math.div(210, 1920 - 295));
      .title {
        font-size: 20px;
        line-height: math.div(27, 20);
        color: $sub-color-6;
        margin-bottom: 28px;
      }
      .desc {
        line-height: 2;
      }
    }
    .pic {
      width: math.percentage(math.div(1090, 1920 - 295));
      order: 2;
    }
  }
  &-pagination {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 35px;
    z-index: 29;
    width: 320px;
    border-bottom: 4px solid $sub-color-2;
    padding: 0 0 30px 20px;
    margin-left: math.percentage(math.div(280, 1920 - 295));
    ::v-deep {
      .p-item {
        width: 9px;
        height: 9px;
        background: $sub-color-7;
        border-radius: 50%;
        transition: 300ms;
        position: relative;
        + .p-item {
          margin-left: 25px;
        }
        &::before {
          content: '';
          position: absolute;
          width: 1px;
          bottom: 100%;
          height: 0;
          background: $sub-color-2;
          margin-bottom: 5px;
          left: 4px;
          transition: 300ms;
        }
      }
      .p-active-item {
        background: $sub-color-2;
        &::before {
          height: 20px;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-2) {
    &-swiper {
      margin-top: math.percentage(math.div(-505, 1920));
    }
  }
  @media screen and (max-width: $res-point-3) {
    .bg {
      padding-left: 295px - 60px;
    }
    &-swiper {
      margin-left: 295px - 60px;
    }
    &-nav {
      .item {
        + .item {
          margin-left: 75px * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-5) {
    .bg {
      padding-left: 5%;
    }
    &-swiper {
      margin-left: 5%;
      margin-top: math.percentage(math.div(-555, 1920));
    }
    &-nav {
      .item {
        + .item {
          margin-left: 75px * 0.8 * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    .bg {
      background: none !important;
      padding: 0 5%;
    }
    &-swiper {
      margin-top: 0;
      margin-right: 5%;
    }
    &-top {
      border-top: 1px solid rgba(14, 58, 135, 0.2);
      padding-bottom: math.percentage(math.div(60, 1434));
      padding-top: math.percentage(math.div(80, 1434));
    }
    &-card {
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
      color: $text-font-5;
      .txts {
        width: auto;
        flex: 1;
        overflow: hidden;
        padding-top: math.percentage(math.div(60, 1434));
        padding-left: 30px;
        .title {
          font-size: 18px;
          color: $sub-color-2;
          margin-bottom: 18px;
        }
      }
      .pic {
        margin-left: 40px;
        width: math.percentage(math.div(1090 * 0.8, 1920 - 295));
      }
    }
    &-pagination {
      margin-top: 40px;
      justify-content: center;
      position: static;
      width: 100%;
      border-bottom: none;
      padding: 0;
      margin-left: 0;
      ::v-deep {
        .p-item {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: $res-point-7) {
    &-card {
      background: none;
      border-radius: 0;
      flex-wrap: wrap;
      .txts {
        width: 320px;
        max-width: 100%;
        order: 2;
        padding: 15px 15px 0 15px;
        flex: none;
        .title {
          font-weight: bold;
        }
      }
      .pic {
        width: 100%;
        margin-left: 0;
        order: 1;
      }
    }
    &-pagination {
      margin-top: math.div(40, 600) * 100vw;
      ::v-deep {
        .p-item {
          + .p-item {
            margin-left: math.div(25, 600) * 100vw;
          }
        }
      }
    }
  }
  @media screen and (max-width: $res-point-9) {
    &-card {
      .txts {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>