<template>
  <div class="SJXPoint">
    <img
      :src="require('@/assets/ShuJiuXiang/down-arrow@2x.png')"
      alt=""
      @click="scrollToOneScreen"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import $ from 'jquery';
export default {
  methods: {
    scrollToOneScreen() {
      $('html,body').stop().animate({ scrollTop: this.visibleWH.height }, 800);
    },
  },
  computed: {
    ...mapState(['isMobile', 'visibleWH']),
  },
};
</script>

<style lang="scss" scoped>
.SJXPoint {
  position: absolute;
  left: 40px;
  bottom: 25px;
  z-index: 9;
  animation: graduallyAppear 2s infinite both;
  cursor: pointer;
  > img {
    width: 26px;
  }
}
</style>